import { ContactFormProps } from "storyblok/components";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import Link from "next/link";
import ReactDOMServer from "react-dom/server";

import {
    ARROW_RIGHT,
    CHECK,
    Checkbox,
    Heading,
    Input,
    Toggle
} from "components/ui";
import {
    ContactPageBackground,
    Description,
    DesktopImage,
    FormDisclaimer,
    FormErrorButton,
    FormErrorWrapper,
    InputHeading,
    InputWrapper,
    PhoneNumberWrapper,
    SelectWrapper,
    StyledAnchor,
    StyledButton,
    StyledContactForm,
    StyledContactFormWrapper,
    StyledInput,
    StyledRadio,
    StyledSelect,
    SubmitButtonWrapper,
    SuccessBlock,
    SuccessIcon,
    SuccessTitle,
    TimeWrapper,
    ToggleAndTimeWrapper,
    ToggleInputWrapper
} from "components/contactForm/ContactForm.styled";
import { Offices } from "components/offices/Offices";
import { TextArea } from "components/ui/textArea";

import { useMediaQueryContext } from "helpers/hooks";

interface FormInputFields {
    callMeBack: string;
    jobdescription: string;
    email: string;
    fullName: string;
    message: string;
    callBackMoment1: string;
    callBackMoment2: string;
    phoneNumber: string;
    department: string;
}

export function ContactForm({ blok }: ContactFormProps): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();
    const {
        title,
        description,
        submitButtonText,
        offices,
        inVacancyRecruiterCard
    } = blok;

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [hasErrorInForm, setHasErrorInForm] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [submitterData, setSubmitterData] = useState<{
        name: string;
        email: string;
    }>();
    const [selectedDepartment, setSelectedDepartment] = useState({
        email: "",
        title: ""
    });

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        reset,
        getValues,
        formState: { errors }
    } = useForm<FormInputFields>({
        defaultValues: {
            callBackMoment1: "Nee",
            callBackMoment2: "Nee",
            callMeBack: "Nee",
            jobdescription: "Zorgprofessional"
        }
    });

    // eslint-disable-next-line
    const onSubmit: SubmitHandler<any> = async function (
        data: FormInputFields
    ) {
        setIsFormLoading(true);

        // eslint-disable-next-line
        const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(
            <div>
                <b>Naam:</b> {data.fullName}
                <br />
                <br />
                <b>Email:</b> {data.email}
                <br />
                <br />
                <b>Ik ben een:</b> {data.jobdescription}
                <br />
                <br />
                {selectedDepartment && (
                    <div>
                        <b>Vakgebied:</b> {selectedDepartment.title}
                        <br />
                        <br />
                    </div>
                )}
                <b>Bericht:</b>
                <br />
                <p>{data.message}</p>
                <br />
                <b>Ik wil graag worden teruggebeld:</b>{" "}
                {data.callMeBack || "Nee"}
                <br />
                <br />
                {data.callMeBack &&
                    (data.callBackMoment1 === "Ja" ||
                        data.callBackMoment2 === "Ja") && (
                        <div>
                            <b>Op deze tijdstippen:</b>
                            <br />
                            <ul>
                                {data.callBackMoment1 === "Ja" && (
                                    <li>
                                        ‘s ochtends (tussen 9:00 en 12:00 uur)
                                    </li>
                                )}

                                {data.callBackMoment2 === "Ja" && (
                                    <li>
                                        ‘s middags (tussen 12:00 en 17:00 uur)
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                {data.callMeBack && (
                    <div>
                        <b>Op telefoonnummer:</b> {data.phoneNumber}
                        <br />
                        <br />
                    </div>
                )}
            </div>
        );

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const response = await fetch(`/api/mail`, {
            body: JSON.stringify({
                from: "Noreply <noreply@leadhealthcare.nl>",
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                message: emailBodyHTML,
                subject: `Contactformulier ${data.fullName}`,
                to: selectedDepartment.title
                    ? `Noreply ${selectedDepartment.email}`
                    : process.env.MAILGUN_RECEIVER
            }),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST"
        });

        if (response.status === 200) {
            reset();
            setSubmitterData({ email: data.email, name: data.fullName });
            setIsFormSubmitted(true);
            setIsFormLoading(false);

            if (typeof window !== "undefined") {
                window?.dataLayer?.push({
                    data: {
                        department: selectedDepartment.title ?? "N/A",
                        email: data.email,
                        form_id: Buffer.from(
                            selectedDepartment.title ?? "N/A"
                        ).toString("base64"),
                        name: data.fullName,
                        phone: data.phoneNumber ?? "N/A",
                        region: "N/A",
                        title: "N/A"
                    },
                    event: "form_submit_contact"
                });
            }
        } else {
            setHasErrorInForm(true);
            setIsFormLoading(false);
        }
    };

    const formErrorBlock = (
        <FormErrorWrapper>
            Er is iets mis gegaan bij het versturen van het formulier, probeer
            het later nog eens.
            <FormErrorButton
                buttonType="primary"
                onClick={() => setHasErrorInForm(false)}
            >
                Opnieuw proberen
            </FormErrorButton>
        </FormErrorWrapper>
    );

    const formSuccessBlock = (
        <SuccessBlock id="gtm-form-submitted">
            <SuccessTitle>
                <SuccessIcon
                    icon={CHECK}
                    size={30}
                    color="var(--color-green-50)"
                />
                Je bericht is verstuurd
                {submitterData && submitterData.name
                    ? `, ${submitterData.name}!`
                    : "!"}
            </SuccessTitle>
            Je ontvangt een bevestiging
            {submitterData && submitterData.email ? (
                <b>{` op ${submitterData.email}. `}</b>
            ) : (
                ". "
            )}
            Wij proberen je binnen twee werkdagen van reactie te voorzien!
        </SuccessBlock>
    );

    /* Registering the textarea separately because the component doesn't for allow direct registers */
    register("message");

    const showDepartmentSelect = watch("jobdescription") === "Zorgprofessional";

    const departments = [
        { email: "werkenbijapotheek@leadhealthcare.nl", title: "Apotheker" },
        {
            email: "werkenbijapotheek@leadhealthcare.nl",
            title: "Apothekersassistent"
        },
        { email: "werkenbij.zorg@leadhealthcare.nl", title: "Basisarts" },
        { email: "werkenbij.zorg@leadhealthcare.nl", title: "GZ Psycholoog" },
        {
            email: "werkenbij.zorg@leadhealthcare.nl",
            title: "Specialist Ouderengeneeskunde"
        },
        { email: "werkenbijapotheek@leadhealthcare.nl", title: "Student" },
        { email: "zzprecruitment@leadhealthcare.nl", title: "ZZP’er" }
    ];

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = departments.find(
            department => department.email === event.target.value
        );
        setSelectedDepartment(selectedOption || { email: "", title: "" });
    };

    return (
        <StyledContactFormWrapper>
            <ContactPageBackground />
            <StyledContactForm
                leftMargin={
                    inVacancyRecruiterCard !== undefined &&
                    !inVacancyRecruiterCard
                }
            >
                <Heading headingLevel={1}>{title}</Heading>
                <Description>{description}</Description>
                {hasErrorInForm ? (
                    formErrorBlock
                ) : isFormSubmitted ? (
                    formSuccessBlock
                ) : (
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {!inVacancyRecruiterCard && (
                            <InputWrapper>
                                <InputHeading>Ik ben een</InputHeading>
                                <StyledRadio
                                    {...register("jobdescription")}
                                    label="Zorgprofessional"
                                    value="Zorgprofessional"
                                    checked={
                                        watch("jobdescription") ===
                                        "Zorgprofessional"
                                    }
                                />
                                <StyledRadio
                                    {...register("jobdescription")}
                                    label="Opdrachtgever"
                                    value="Opdrachtgever"
                                    checked={
                                        watch("jobdescription") ===
                                        "Opdrachtgever"
                                    }
                                />
                            </InputWrapper>
                        )}

                        {showDepartmentSelect && (
                            <InputWrapper>
                                <SelectWrapper>
                                    <StyledSelect
                                        {...register("department")}
                                        onChange={handleChange}
                                    >
                                        <option value="">
                                            Kies een vakgebied
                                        </option>
                                        {departments.map(
                                            (department, index) => (
                                                <option
                                                    key={index}
                                                    value={department.email}
                                                    data-title={
                                                        department.title
                                                    }
                                                >
                                                    {department.title}
                                                </option>
                                            )
                                        )}
                                    </StyledSelect>
                                </SelectWrapper>
                            </InputWrapper>
                        )}

                        <InputWrapper isFlex>
                            <StyledInput
                                label="Naam"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                errorMessage={errors.fullName?.message}
                                {...register("fullName", {
                                    required: {
                                        message: "Vul een naam in",
                                        value: true
                                    }
                                })}
                                hasRightMargin
                            />
                            <StyledInput
                                label="E-mail"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                errorMessage={errors.email?.message}
                                {...register("email", {
                                    pattern: {
                                        message: "Vul een geldig emailadres in",
                                        value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                                    },
                                    required: {
                                        message: "Vul een emailadres in",
                                        value: true
                                    }
                                })}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextArea
                                label="Bericht"
                                onChange={e =>
                                    setValue(
                                        "message",
                                        // eslint-disable-next-line
                                        // @ts-ignore
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                        e.target.value
                                    )
                                }
                            />
                        </InputWrapper>
                        <ToggleInputWrapper>
                            <ToggleAndTimeWrapper>
                                <Toggle
                                    label="Bel mij terug"
                                    activeValue="Ja"
                                    {...register("callMeBack")}
                                    currentValue={watch("callMeBack")}
                                    onChange={() =>
                                        watch("callMeBack") === "Ja"
                                            ? setValue("callMeBack", "Nee")
                                            : setValue("callMeBack", "Ja")
                                    }
                                />
                                {watch("callMeBack") === "Ja" && (
                                    <TimeWrapper>
                                        <InputHeading>
                                            Moment van de werkdag
                                        </InputHeading>
                                        <Checkbox
                                            label="‘s ochtends (tussen 9:00 en 12:00 uur)"
                                            currentValue={watch(
                                                "callBackMoment1"
                                            )}
                                            activeValue="Ja"
                                            {...register("callBackMoment1")}
                                            onChange={() =>
                                                watch("callBackMoment1") ===
                                                "Ja"
                                                    ? setValue(
                                                          "callBackMoment1",
                                                          "Nee"
                                                      )
                                                    : setValue(
                                                          "callBackMoment1",
                                                          "Ja"
                                                      )
                                            }
                                        />
                                        <Checkbox
                                            label="‘s middags (tussen 12:00 en 17:00 uur)"
                                            currentValue={watch(
                                                "callBackMoment2"
                                            )}
                                            activeValue="Ja"
                                            {...register("callBackMoment2")}
                                            onChange={() =>
                                                getValues("callBackMoment2") ===
                                                "Ja"
                                                    ? setValue(
                                                          "callBackMoment2",
                                                          "Nee"
                                                      )
                                                    : setValue(
                                                          "callBackMoment2",
                                                          "Ja"
                                                      )
                                            }
                                        />
                                    </TimeWrapper>
                                )}
                            </ToggleAndTimeWrapper>
                            {watch("callMeBack") === "Ja" && (
                                <PhoneNumberWrapper>
                                    <Input
                                        label="Telefoonnummer"
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        errorMessage={
                                            errors.phoneNumber?.message
                                        }
                                        {...register("phoneNumber", {
                                            pattern: {
                                                message:
                                                    "Vul een geldig telefoonnummer in",
                                                value: /\+?\d+/
                                            },
                                            required: {
                                                message:
                                                    "Vul een telefoonnummer in",
                                                value: true
                                            }
                                        })}
                                    />
                                </PhoneNumberWrapper>
                            )}
                        </ToggleInputWrapper>
                        <SubmitButtonWrapper>
                            <FormDisclaimer>
                                Door op de knop “{submitButtonText}” te klikken,
                                ga je akkoord met onze{" "}
                                <Link
                                    legacyBehavior
                                    href="/algemene-voorwaarden"
                                    passHref
                                    prefetch={false}
                                >
                                    <StyledAnchor>
                                        gebruiksvoorwaarden
                                    </StyledAnchor>
                                </Link>{" "}
                                en ons{" "}
                                <Link
                                    legacyBehavior
                                    href="/privacy-cookies"
                                    passHref
                                    prefetch={false}
                                >
                                    <StyledAnchor>privacybeleid</StyledAnchor>
                                </Link>
                                .
                            </FormDisclaimer>
                            <StyledButton
                                buttonType="primary"
                                icon={ARROW_RIGHT}
                                reversedOrder
                                disabled={isFormLoading}
                            >
                                {submitButtonText}
                            </StyledButton>
                        </SubmitButtonWrapper>
                        {isDesktopView && <DesktopImage />}
                    </form>
                )}
            </StyledContactForm>
            {!inVacancyRecruiterCard && <Offices offices={offices} />}
        </StyledContactFormWrapper>
    );
}
