import React from "react";
import dynamic from "next/dynamic";

import { Footer, Header, Metadata } from "components/shared";

import { Main, Wrapper } from "./Page.styled";

import { useMediaQueryContext } from "helpers/hooks";

const Breadcrumbs = dynamic(
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    //@ts-ignore
    () => import("components/ui").then(m => m.Breadcrumbs),
    { ssr: false }
);

type PageProps = {
    title?: string;
    description?: string;
    hideStickyHeader?: boolean;
    children: React.ReactNode;
};

export function Page({
    title,
    description,
    hideStickyHeader = false,
    children
}: PageProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();

    return (
        <>
            <Metadata title={title} description={description} />
            <Wrapper>
                <Header hideStickyHeader={hideStickyHeader} />
                {/*eslint-disable-next-line*/}
                {/* @ts-ignore*/}
                {!isMobileView && <Breadcrumbs />}
                <Main>{children}</Main>
                <Footer />
            </Wrapper>
        </>
    );
}
